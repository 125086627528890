import { r as requestTimeout } from './requestTimeout-825f721a.js';
import { g as getDocument, i as isSSR } from './element-d46853d9.js';
import { n as getRelativePosition } from './MutationObserverSafe-7a5dacdc.js';

const RIPPLE_CONTAINER_CLASSNAME = 'dynamic-ripple-container-element';
const RIPPLE_CLASSNAME = 'ripple';
const RIPPLE_CLASS_NAME_ACTIVE = 'ripple-active';
const RIPPLE_CLASS_NAME_HELD = 'held';
const RIPPLE_CLASS_NAME_DONE = 'done';
const RIPPLE_SIZE_COEFFICIENT = 1.412;
const RIPPLE_DEFAULT_DURATION = 750; // larger than animation: duration in css
const getPositionFromCenter = (ref, factor) => {
  return { x: (ref === null || ref === void 0 ? void 0 : ref.clientWidth) / factor || 0, y: (ref === null || ref === void 0 ? void 0 : ref.clientHeight) / factor || 0 };
};
const applyRipple = (event, parent, { fromCenter, onCompleted, sizingRefElement } = {}) => {
  var _a, _b, _c;
  const ref = sizingRefElement || parent;
  parent === null || parent === void 0 ? void 0 : parent.classList.add(RIPPLE_CLASS_NAME_ACTIVE);
  const factor = 2;
  let position;
  if (fromCenter) {
    position = getPositionFromCenter(ref, factor);
  }
  else {
    position = getRelativePosition({ x: event === null || event === void 0 ? void 0 : event.clientX, y: event === null || event === void 0 ? void 0 : event.clientY }, ref);
  }
  let size;
  if ((ref === null || ref === void 0 ? void 0 : ref.clientWidth) === (ref === null || ref === void 0 ? void 0 : ref.clientHeight)) {
    size = (parent === null || parent === void 0 ? void 0 : parent.clientWidth) * RIPPLE_SIZE_COEFFICIENT;
  }
  else {
    size = Math.sqrt((ref === null || ref === void 0 ? void 0 : ref.clientWidth) * (ref === null || ref === void 0 ? void 0 : ref.clientWidth) + (ref === null || ref === void 0 ? void 0 : ref.clientHeight) * (ref === null || ref === void 0 ? void 0 : ref.clientHeight));
  }
  const rippleContainer = (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.createElement('div');
  if (rippleContainer) {
    rippleContainer.className = `${RIPPLE_CONTAINER_CLASSNAME}`;
    rippleContainer.style.pointerEvents = 'none';
    const rippleElement = (_b = getDocument()) === null || _b === void 0 ? void 0 : _b.createElement('div');
    const dim = `${size * factor}px`;
    rippleElement.style.width = dim;
    rippleElement.style.height = dim;
    rippleElement.style.marginLeft = `${position.x - size}px`;
    rippleElement.style.marginTop = `${position.y - size}px`;
    rippleElement.style.pointerEvents = 'none';
    rippleElement.className = `${RIPPLE_CLASSNAME}`;
    rippleContainer.appendChild(rippleElement);
    parent.appendChild(rippleContainer);
    !isSSR() &&
      requestTimeout(() => {
        rippleElement === null || rippleElement === void 0 ? void 0 : rippleElement.classList.add(RIPPLE_CLASS_NAME_HELD);
      }, 0);
    const release = () => {
      var _a;
      (_a = getDocument()) === null || _a === void 0 ? void 0 : _a.removeEventListener('mouseup', release, true);
      rippleElement === null || rippleElement === void 0 ? void 0 : rippleElement.classList.add(RIPPLE_CLASS_NAME_DONE);
      !isSSR() &&
        requestTimeout(() => {
          const containers = parent === null || parent === void 0 ? void 0 : parent.getElementsByClassName(`${RIPPLE_CONTAINER_CLASSNAME}`);
          if (containers.length) {
            if (containers.length === 1) {
              parent === null || parent === void 0 ? void 0 : parent.classList.remove(RIPPLE_CLASS_NAME_ACTIVE);
              onCompleted === null || onCompleted === void 0 ? void 0 : onCompleted();
            }
            parent === null || parent === void 0 ? void 0 : parent.removeChild(containers[0]);
          }
        }, RIPPLE_DEFAULT_DURATION);
    };
    (_c = getDocument()) === null || _c === void 0 ? void 0 : _c.addEventListener('mouseup', release, true);
  }
};

export { RIPPLE_CONTAINER_CLASSNAME as R, applyRipple as a };
